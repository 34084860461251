import { Controller } from "@hotwired/stimulus"
import {get } from "@rails/request.js"
import { routes } from "../routes"

export default class extends Controller {
  static targets = ["select"]

  async reload(e) {
    e.preventDefault()

    const response = await get(routes.queryFornecedoresPath(), { responseKind: "json" })

    if (!response.ok) { return }

    const fornecedoresData = await response.json
    this.selectTarget.innerHTML = '<option></option>' +
      fornecedoresData.map(data => `<option value="${data[1]}">${data[0]}</option>`).join()
  }
}
