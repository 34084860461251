import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { routes } from "../routes"

const fields = [
  'conta_contabil_id', 'descricao_detalhada', 'marca', 'serie', 'modelo',
  'unidade_de_medida_id', 'unidade_patrimonial_id', 'complemento_de_localizacao',
  'fornecedor_id', 'numero_do_documento', 'serie_do_documento',
  'tipo_de_documento', 'preco_unitario', 'processo', 'processo_de_pagamento', 'empenho'
];
const formatDate = (date) => /(\d{4})\-(\d{2})\-(\d{2})/.exec(date).slice(1, 4).reverse().join('/');

export default class extends Controller {
  static targets = ["copyFrom"]

  async copy() {
    const response = await get(
      routes.copyBemPath({ ativo: this.copyFromTarget.value }),
      { responseKind: "json" }
    );

    if (response.ok) {
      const bem = await response.json
      fields.forEach(field => {
        document.getElementById(`bem_patrimonial_${field}`).value = bem[field];
      });
      document.getElementById('bem_patrimonial_data_de_emissao_do_documento').value =
        formatDate(bem.data_de_emissao_do_documento);
    } else {
      this.clear()
    }
  }

  clear() {
    fields.forEach(field => {
      document.getElementById(`bem_patrimonial_${field}`).value = '';
      document.getElementById('bem_patrimonial_data_de_emissao_do_documento').value = '';
    });
  }
}
