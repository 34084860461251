String.prototype.parseSequence = function() {
    var subsequences = this.split(';');
    var sequenceValid = function() {
        var regex = /^(\d+)(\-\d+)?$/;
        var valid = function(subseq) { return regex.exec(subseq) !== null };
        return subsequences.every(valid);
    };
    if (!sequenceValid()) { throw 'Sequência inválida' };

    var parseSubsequence = function(subseq) {
        var toInt = function(e) { return parseInt(e) };
        var items = subseq.split('-').map(toInt);
        if (items.length == 1)
            return items;
        else {
            var result = [];
            for (var i = items[0]; i <= items[1]; i++)
                result.push(i);
            return result;
        }
    };
    return [].concat.apply([], subsequences.map(parseSubsequence));
};
