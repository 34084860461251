import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { routes } from "../routes"

export default class extends Controller {
  static values = {
    bemId: String,
  }

  static targets = [
    "pvu", "pub", "ec", "valorNovo",
    "pontosPvu", "pontosPub", "pontosEc",
    "fatorReavaliacao", "avaliacaoFinal"
  ]

  async update() {
    const response = await get(
      routes.tabelaDeAjustePath({
        bem_id: this.bemIdValue,
        pvu: this.pvuTarget.value,
        pub: this.pubTarget.value,
        ec: this.ecTarget.value,
        valor_novo: this.valorNovoTarget.value,
      }),
      { responseKind: "json" }
    )

    if (!response.ok) { return }

    const dados = await response.json;

    this.pontosPvuTarget.innerHTML = dados.pontos_pvu || ''
    this.pontosPubTarget.innerHTML = dados.pontos_pub || ''
    this.pontosEcTarget.innerHTML = dados.pontos_ec || ''
    this.fatorReavaliacaoTarget.innerHTML = dados.fator_de_reavaliacao || ''
    this.avaliacaoFinalTarget.innerHTML = dados.avaliacao_final || ''
  }
}
