// mostrar e ocultar seletivamente o formulário de incorporação de bens de projeto
// se houver algum check selecionado
document.addEventListener('turbo:load', function() {
  if (!document.getElementById('pagina-incorporacao')) { return; }

  const checks = () => [...document.getElementsByClassName('check-incorporacao')];
  const isAnyChecked = function() {
    return checks().
      map((check) => !!check.checked).
      reduce((b1, b2) => b1 || b2, false);
  };

  const showFormIfAnyChecked = function() {
    const form = document.getElementById('form-incorporacao');
    isAnyChecked() ? showElement(form) : hideElement(form);
  };

  showFormIfAnyChecked();

  checks().forEach((check) => check.addEventListener('change', showFormIfAnyChecked));
});
