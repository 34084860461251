document.addEventListener('turbo:load', function() {
  if (!document.getElementById('tabela-ajustes')) { return; }

  const pvuElement = (ativo) => document.getElementById(`pvu_${ativo}`);
  const pubElement = (ativo) => document.getElementById(`pub_${ativo}`);
  const ecElement = (ativo) => document.getElementById(`ec_${ativo}`);
  const valorNovoElement = (ativo) => document.getElementById(`valor_novo_${ativo}`);

  for (const element of document.querySelectorAll('.repetir-lancamentos')) {
    element.addEventListener('click', function(event) {
      let tr = event.target;
      if (tr.getAttribute('data-bem-id') == null) {
        tr = event.target.parentNode;
      }

      let ativoDe = tr.getAttribute('data-bem-ativo');

      const pvu = pvuElement(ativoDe).value;
      const pub = pubElement(ativoDe).value;
      const ec = ecElement(ativoDe).value;
      const valorNovo = valorNovoElement(ativoDe).value;

      const para = prompt(`Repetir lançamentos do ativo ${ativoDe} para`);
      if (para != null) {
        const bemId = tr.getAttribute('data-bem-id');
        let sequencia = [];
        try {
          sequencia = para.parseSequence();
        } catch(e) {
          alert(e);
        }

        sequencia.forEach(function(ativo) {
          if (valorNovoElement(ativo) != null) {
            pvuElement(ativo).value = pvu;
            pubElement(ativo).value = pub;
            if (ecElement(ativo) != null) {
              ecElement(ativo).value = ec;
            }
            valorNovoElement(ativo).value = valorNovo;
            triggerEventChange(valorNovoElement(ativo));
          }
        });
      }
    });
  }

  for (const element of document.querySelectorAll('.repetir-valor-para-todos')) {
    element.addEventListener('click', function(event) {
      let tr = event.target;
      if (tr.getAttribute('data-bem-id') == null) {
        tr = event.target.parentNode;
      }
      const bemId = tr.getAttribute('data-bem-id');
      const ativoDe = tr.getAttribute('data-bem-ativo');

      const retorno = confirm(`Repetir lançamentos do ativo ${ativoDe} para todos?`);
      if (retorno == true) {
        const pvu = pvuElement(ativoDe).value;
        const pub = pubElement(ativoDe).value;
        const ec = ecElement(ativoDe).value;
        const valorNovo = valorNovoElement(ativoDe).value;

        const ativos = tr.getAttribute('data-ativos');
        let sequencia = [];
        try {
          sequencia = ativos.parseSequence();;
        } catch(e) {
          alert(e);
        }

        sequencia.forEach(function(ativo) {
          pvuElement(ativo).value = pvu;
          pubElement(ativo).value = pub;
          ecElement(ativo).value = ec;
          valorNovoElement(ativo).value = valorNovo;
          triggerEventChange(valorNovoElement(ativo));
        });
      }
    });
  }

  const dataCaluloElement = document.getElementById('data_de_calculo');
  if (dataCaluloElement) {
    dataCaluloElement.addEventListener('change', function(e) {
      const form = document.getElementById('link-fechar-ajuste');
      const dataCalculo = dataCaluloElement.value;
      const busca = form.getAttribute('href').split('?')[1].split('&')[0]
      const href = form.getAttribute('href').replace(busca, `data_de_calculo=${dataCalculo}`);
      form.setAttribute('href', href);
    });
  }

  for (const element of document.querySelectorAll('.apagar-lancamento')) {
    element.addEventListener('click', function(event) {
      let tr = event.target;
      if (tr.getAttribute('data-bem-id') == null) {
        tr = event.target.parentNode;
      }

      const bemId = tr.getAttribute('data-bem-id');
      const ativo = tr.getAttribute('data-bem-ativo');

      const retorno = confirm(`Apagar ajuste do ativo ${ativo}?`);
      if (retorno == true) {
        pvuElement(ativo).value = '';
        pubElement(ativo).value = '';
        ecElement(ativo).value = '';
        valorNovoElement(ativo).value = '';
        triggerEventChange(valorNovoElement(ativo));
      }
    });
  }

  function triggerEventChange(el) {
    var event = document.createEvent('HTMLEvents');
    event.initEvent('change', true, false);
    el.dispatchEvent(event);
  }
});
