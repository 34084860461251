import c0 from './carregar_fornecedores_controller';
import c1 from './carregar_unidades_controller';
import c2 from './copiar_bem_controller';
import c3 from './linha_tabela_ajustes_controller';
export const definitions = [
	{identifier: 'carregar-fornecedores', controllerConstructor: c0},
	{identifier: 'carregar-unidades', controllerConstructor: c1},
	{identifier: 'copiar-bem', controllerConstructor: c2},
	{identifier: 'linha-tabela-ajustes', controllerConstructor: c3},
];
