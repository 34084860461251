import { parameterize } from "@uenf/layout/src/js/utils";

const routes = {
  tabelaDeAjustePath: (params) => `${RELATIVE_URL_PATH}/ajustes/atualizar_tabela?${parameterize(params)}`,
  copyBemPath: (params) => `${RELATIVE_URL_PATH}/bens_patrimoniais/copy?${parameterize(params)}`,
  queryUnidadesPatrimoniaisPath: () => `${RELATIVE_URL_PATH}/unidades_patrimoniais/query`,
  queryFornecedoresPath: () => `${RELATIVE_URL_PATH}/fornecedores/query`,
};

export { routes };
