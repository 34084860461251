import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { routes } from "../routes"

export default class extends Controller {
  static targets = ["select"]

  async reload(e) {
    e.preventDefault();

    const response = await get(routes.queryUnidadesPatrimoniaisPath(), { responseKind: "json" })

    if (!response.ok) { return }

    const unidadesData = await response.json
    this.selectTarget.innerHTML =
      "<option></option>" +
      unidadesData
        .map(pair => `<option value="${pair[1]}">${pair[0]}</option>`)
        .join();
  }
}
